.closeButton,
.addButton,
.checkButton {
  appearance: none;
  border: none;

  color: white;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 12px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-100%, 100%);
  transition: transform 0.5s;
}

.closeButton {
  background-color: red;
}

.addButton {
  background-color: green;
}

.checkButton {
  background-color: blue;
}

.closeButton:focus,
.addButton:focus,
.checkButton:focus {
  outline: none;
}

.x {
  font-size: 15px;
  font-weight: 400;
}

.closeButton.hidden,
.addButton.hidden,
.checkButton.hidden {
  transform: translate(-100%, 100%);
  display: none;
}

.closeButton:not(.hidden),
.addButton:not(.hidden),
.checkButton:not(.hidden) {
  transform: translate(0, 0);
}
