.file {
  height: 12rem;
  width: 12rem;
  margin: 1.5rem;
  backdrop-filter: blur(6px);
  border: 1px solid white;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  transition: border 200ms ease;
  color: white;
  font-size: 15px;
  transition: all 200ms ease;
}

.file:hover {
  border: 1px solid gray;
  transform: scale(1.1);
}

.file:hover span {
  color: lightgray;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content span {
  transition: all 200ms ease;
}

.content svg {
  width: 20%;
  height: 20%;
}
