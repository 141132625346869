.frame {
    background-color: var(--bs-body-color);
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 3px 3px 7px 0 rgba(0, 0, 0, 0.2),
    -4px -4px 9px 0 rgba(255, 255, 255, 0.4);
    background: rgba(33, 37, 41, 0.7);
    -webkit-animation: puff-in-center .8s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
    animation: puff-in-center .8s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
    left: 5%;
    top: 90px;
    width: 100vw;
    height: 85vh;
    margin: auto;
    /* Flexbox settings to center child elements */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 91vw;
    max-height: 1080px;
}

.reportFrame {
    border-radius: 10px;
    width: 90vw;
    height: 85vh;
    -webkit-animation: puff-in-center 1.3s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
    animation: puff-in-center 1.3s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}

.title {
    backdrop-filter: blur(100px);
    border-radius: 10px;
    padding: .5rem;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);
}

@-webkit-keyframes puff-in-center {
    0% {
      -webkit-transform: scale(2);
              transform: scale(2);
      -webkit-filter: blur(4px);
              filter: blur(4px);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes puff-in-center {
    0% {
      -webkit-transform: scale(2);
              transform: scale(2);
      -webkit-filter: blur(4px);
              filter: blur(4px);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }