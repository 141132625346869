@keyframes howto-menu-enter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes howto-menu-exit {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

.explorer {
  display: none;
}

.explorer.enter {
  animation: howto-menu-enter 500ms ease;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.explorer.exit {
  animation: howto-menu-exit 500ms ease;
  display: none;
}
