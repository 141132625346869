.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  max-width: unset;
  z-index: 3;
}

.modalContent {
  max-width: 1250px;
  min-width: 500px;
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  position: relative;
  animation: fadeInDown 0.5s forwards;
  align-items: start;
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  width: 300px;
  background-color: #212529;
}

.show {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.hide {
  visibility: hidden;
  opacity: 0;
}

.header {
  display: flex;
  justify-content: center;
  padding: 1rem;
  width: 100%;
}

.header h2 {
  margin: 0;
}

.content {
  padding: 1rem;
  width: 100%;
  justify-content: center;
  display: flex;
}

.footer {
  background-color: #212529;
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.footer button {
  margin-left: 1rem;
  border-radius: 0.25rem;
  border: 1px solid white;
  color: white;
  transition: all 200ms ease;
}

.footer button:hover {
  color: gainsboro;
  border: 1px solid darkgray;
}
