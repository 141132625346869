.menuToggler {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  padding: 2rem;
  border-radius: 50%;
  z-index: 2;
  opacity: 0;
}

.menuToggler:hover {
  cursor: pointer;
}

.menuToggler:checked + label {
  background: transparent;
}

.menuToggler:checked + label:before,
.menuToggler:checked + label:after {
  top: 0;
  width: 40px;
  transform-origin: 50% 50%;
  background: rgba(0, 0, 0, 1);
}

.menuToggler:checked:hover + label:before,
.menuToggler:checked:hover + label:after {
  background: rgba(76, 75, 75, 1);
}

.menuToggler:checked + label:before {
  transform: rotate(45deg);
}

.menuToggler:checked + label:after {
  transform: rotate(-45deg);
}

.menuToggler ~ ul {
  margin-bottom: 0;
}

.menuToggler:checked ~ ul .menuItem {
  opacity: 1;
}

.menuToggler:checked ~ ul .menuItem:nth-child(1) {
  transform: rotate(0deg) translateX(-110px);
}

.menuToggler:checked ~ ul .menuItem:nth-child(2) {
  transform: rotate(60deg) translateX(-110px);
}

.menuToggler:checked ~ ul .menuItem:nth-child(3) {
  transform: rotate(120deg) translateX(-110px);
}

.menuToggler:checked ~ ul .menuItem:nth-child(4) {
  transform: rotate(180deg) translateX(-110px);
}

.menuToggler:checked ~ ul .menuItem:nth-child(5) {
  transform: rotate(240deg) translateX(-110px);
}

.menuToggler:checked ~ ul .menuItem:nth-child(6) {
  transform: rotate(300deg) translateX(-110px);
}

.menuToggler:checked ~ ul .menuItem a {
  pointer-events: auto;
}

.menuToggler + label {
  width: 40px;
  height: 5px;
  display: block;
  z-index: 1;
  border-radius: 2.5px;
  transition: transform 0.5s, top 0.5s;
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

.menuToggler + label:before,
.menuToggler + label:after {
  width: 40px;
  height: 5px;
  display: block;
  z-index: 1;
  border-radius: 2.5px;
  transition: transform 0.5s, top 0.5s;
  content: "";
  position: absolute;
  left: 0;
}

.menuToggler + label:before {
  top: 10px;
}

.menuToggler + label:after {
  top: -10px;
}

.menuItem:nth-child(1) a {
  transform: rotate(0deg);
}

.menuItem:nth-child(2) a {
  transform: rotate(-60deg);
}

.menuItem:nth-child(3) a {
  transform: rotate(-120deg);
}

.menuItem:nth-child(4) a {
  transform: rotate(180deg);
}

.menuItem:nth-child(5) a {
  transform: rotate(120deg);
  z-index: 99999;
}

.menuItem:nth-child(1) a {
  transform: rotate(0deg);
}

.menuItem {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 100px;
  height: 100px;
  display: block;
  opacity: 0;
  transition: 0.5s;
  z-index: 3;
}

.menuItem a {
  display: block;
  widows: inherit;
  height: inherit;
  line-height: 100px;
  color: white;
  background: rgba(76, 75, 75, 0.99);
  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
  pointer-events: none;
  transition: 0.2s;
  z-index: 99999;
}

.menuItem:hover a {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.9);
  background: rgba(27, 27, 27, 0.9);
}

.title {
  color: white;
}
