.footerBox {
  background-color: #212529;
  padding: 1rem;
  border: 1.5px solid rgb(154, 157, 160);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top: none;
  z-index: 1;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  overflow: hidden;
  min-height: 350px;
}

.player {
  /* overflow: hidden !important; */
  transition: all 350ms ease;
  height: 100%;
  width: 100%;
  max-height: 1000px;
  min-height: 250px;
  max-width: 600px;
  margin: auto;
}

.content {
  margin: 1rem;
  min-height: 250px;
  max-width: 600px;
}

.title {
  border-bottom: 1.5px solid rgb(154, 157, 160) !important;
  vertical-align: text-top;
  font-size: 1.8rem;
  padding-bottom: 0.15em;
}

.body {
  font-weight: 300;
  font-size: 1.4rem;
}
