.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  max-height: 100vh;
  max-width: unset;
  overflow-y: scroll;
  z-index: 3;
}

.modalContent {
  max-width: 1250px;
  min-width: 600px;
  max-height: 70vh;
  min-height: 650px;
  margin-top: 7rem;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  position: relative;
  animation: fadeInDown 0.5s forwards;
  align-items: start;
  border-radius: 0.25rem;
  border: 1px solid white;
  color: white;
}

.modalContent label {
  display: flex;
  flex-direction: column;
}

.modalContent span {
  color: red;
}

.modalContent.loading {
  margin: auto;
}

.show {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.hide {
  visibility: hidden;
  opacity: 0;
}

.header {
  background-color: #212529;
  padding: 1rem;
  height: 10%;
  border-bottom: 1px solid #ddd;
  min-height: 66px;
}

.header h2 {
  margin: 0;
}

.content {
  background-color: #212529;
  height: 80%;
  padding: 1rem;
}

.content p {
  margin-bottom: unset;
}

.footer {
  background-color: #212529;
  padding: 1rem;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: flex-end;
  height: 10%;
  min-height: 66px
}

.footer button {
  margin-left: 1rem;
  border-radius: 0.25rem;
  border: 1px solid white;
  color: white;
  transition: all 200ms ease;
}

.footer button:hover {
  color: gainsboro;
  border: 1px solid darkgray;
}


.shake {
	-webkit-animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-3-23 14:18:12
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
 @-webkit-keyframes shake-horizontal {
    0%,
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
      -webkit-transform: translateX(-10px);
              transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
      -webkit-transform: translateX(10px);
              transform: translateX(10px);
    }
    80% {
      -webkit-transform: translateX(8px);
              transform: translateX(8px);
    }
    90% {
      -webkit-transform: translateX(-8px);
              transform: translateX(-8px);
    }
  }
  @keyframes shake-horizontal {
    0%,
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
      -webkit-transform: translateX(-10px);
              transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
      -webkit-transform: translateX(10px);
              transform: translateX(10px);
    }
    80% {
      -webkit-transform: translateX(8px);
              transform: translateX(8px);
    }
    90% {
      -webkit-transform: translateX(-8px);
              transform: translateX(-8px);
    }
  }
  