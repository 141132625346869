.form {
    display: flex;
    flex-direction: column;
    width: 400px;
    margin: 0 auto;
}

.inputField {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submitButton {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
}

.form label {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.notificationTable {
    width: 100%;
    border-collapse: collapse;
    margin: 20px auto;
}

.notificationTable label {
    margin-top: 20px;
}

.notificationTable th, .notificationTable td {
    border: 1px solid #ffff;
    padding: 8px;
    text-align: left;
}

.notificationTable th {
    background-color: transparent;
}

.deleteButton {
    background-color: #f44336;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 16px;
}

.notifHolder {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}