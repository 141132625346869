.content {
  padding-top: 7rem;
  padding-bottom: 4rem;
  width: 100%;

  background-color: transparent;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0px;
  z-index: 2;
}

.content > * {
  max-width: 1300px;
  width: 75%;
}

.toast {
  margin-top: 3rem;
  font-size: 20px;
}