.content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 16px;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 450px;
  border: 1px solid #9a9da0;
  border-top: none;
  backdrop-filter: blur(6px);
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.content img {
  width: 15vw;
  max-width: 160px;
}

@media (max-width: 1675px) {
  .content {
    grid-template-columns: repeat(2, 1fr);
  }
}
