.shake {
  -webkit-animation: shake-horizontal 0.8s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-3-23 14:18:12
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}

@-webkit-keyframes blur-out-contract {
  0% {
    -webkit-filter: blur(0.01);
    filter: blur(0.01);
  }
  100% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px) opacity(0%);
    filter: blur(12px) opacity(0%);
  }
}
@keyframes blur-out-contract {
  0% {
    -webkit-filter: blur(0.01);
    filter: blur(0.01);
  }
  100% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px) opacity(0%);
    filter: blur(12px) opacity(0%);
  }
}

.brandLogo {
  display: flex;
  justify-content: center;
  color: white;
  font-size: 6.5rem;
  width: 100%;
  font-family: "Ropa Soft";
  -webkit-animation: blur-out-contract 1s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.25s reverse both;
  animation: blur-out-contract 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.25s
    reverse both;
  user-select: none;
}

.trademark {
  font-size: 2.25rem;
  user-select: none;
  margin-top: 0.5rem;
}

@media (max-width: 768px) {
  .brandLogo {
    font-size: 6rem;
  }
  .trademark {
    font-size: 2.25rem;
  }
  .loginModal {
    width: 450px;
  }
  .loginMessage {
    font-size: 1.4rem;
  }
}

@media (max-width: 480px) {
  .brandLogo {
    font-size: 5rem;
  }
  .trademark {
    font-size: 2rem;
  }
  .loginModal {
    width: 350px;
  }
  .loginMessage {
    font-size: 1.2rem;
  }
}

.modal-content {
  background-color: #221f20 !important;
}

/* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */

.loginWholePage {
  z-index: 2;
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(6px);
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}
.loginModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  background-color: #212529;
  border-radius: 0.5rem;
  padding: 2rem;
  user-select: none;
  animation: fadeInDown 0.75s forwards ease;
  overflow: hidden;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼       Login Modal Main        ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.login-text-white {
  color: white;
  display: flex;
  align-items: left;
}

.loginMessage {
  font-size: 1.5rem;
  color: #fff;
  line-height: 32px;
}

.h4 {
  color: #fff !important;
}

.loginModal.show {
  background: transparent !important;
}

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼    Login Modal Header       ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.loginModalLogo {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.loginModalHeader {
  display: flex;
  justify-content: center !important;
  width: 100%;
  margin: 0rem 0rem 1rem 0rem;
}

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼     Login Modal Body       ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.loginModalBody {
  border-top: none !important;
  border-bottom: none !important;
}

.loginModalRo {
  padding: 2rem 0rem 2rem 0rem !important;
  animation: fadeIn 0.75s forwards ease-out;
  animation-delay: 0.25s;
  opacity: 0;
  display: flex;
  justify-content: center !important;
  width: 100%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼     Login Modal Footer       ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.loginModalFooter {
  display: flex;
  justify-content: center !important;
  width: 100%;
  padding: 1rem;
  border-radius: 0px !important;
}

.loginModalFooterDisc {
  justify-content: center !important;
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
  margin: 1rem 0rem 0rem 0rem;
  color: rgb(154, 157, 160);
  font-size: 0.55em;
  width: 100%;
}

.discButton {
}

.loginModalButton {
  width: 13rem;
  background-color: rgba(255, 255, 255, 0.05) !important;
  border-color: #016bb5 !important;
  transition: all 0.3s ease !important;
  outline: none !important;
  box-shadow: none !important;
}

.loginModalButton:hover {
  transition: all 0.3s ease !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.loginRingLoader {
  justify-content: center;
  display: flex;
  align-items: center;
  min-height: 100vh !important;
  background-color: transparent !important;
}

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼     Disclaimer/Policy       ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.discButton,
.discButton:active {
  background-color: rgba(255, 255, 255, 0.05) !important;
  border: none !important;
  border-radius: 0.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  width: 100%;
  font-size: 1.3rem;
  font-weight: 200;
  transition: 0.3s ease !important;
  outline: none !important;
  box-shadow: none !important;
}

.discButton:hover {
  transition: 0.3s ease !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.loginDiscText {
  font-size: 0.88rem;
  font-weight: 200;
}

/* .loginDiscText.show {
  animation: fadeInTextDown 0.75s forwards ease-out;
} */

@keyframes fadeInTextDown {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.policyButton {
  border-top: 1.5px solid rgb(154, 157, 160);
  padding-top: 0.55rem;
  padding-bottom: 0.35rem;
  width: 100%;
  max-height: auto;
  font-size: 0.78rem;
  font-weight: 400 !important;
  color: rgb(154, 157, 160);
  margin-top: 0.75em;
  text-align: center;
  animation: fadeInTextDown 0.3s forwards ease-out;
}
