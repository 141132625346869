@font-face {
  font-family: "Ropa Soft";
  src: url("../../fonts/Ropa Soft SC PTT W01 Thin.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.navbar {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  align-items: flex-end;
  padding: 0.5rem;
  padding-bottom: 0;
  border-bottom: 5px solid #016bb5;
  background-color: #212529;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

.brandLogo {
  text-align: center;
  height: 50px;
  margin-top: 1rem;
  color: white;
  font-size: 2.4rem;
  max-width: 166px;
  overflow: hidden;
  font-family: "Ropa Soft";
  -webkit-animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.trademark {
  font-size: 0.9rem; /* Make it smaller */
  vertical-align: super; /* Align it to the top */
  line-height: 0.8; /* Adjust line height if needed */
  margin-left: 0.2rem; /* Optional: add some space between the logo and TM */
}

.navLinks {
  display: flex;
  align-items: flex-end;
  list-style: none;
  margin: 0 1rem;
  padding: 0;
  background-color: #343a40;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.navLinks > * {
  text-decoration: none;
}

.navLink {
  padding: 0.5rem;
  align-items: flex-end;
}

.navLink:hover {
  cursor: pointer;
}

.navLink a,
.navText {
  text-decoration: none;
  color: #ffff;
  transition: color 0.2s ease-in-out;
}

.navLink:hover .navText {
  color: lightgray;
}

.active {
  background-color: #016bb5;
  color: #ffff;
  box-shadow: 0px -25px 30px rgba(0, 0, 0, 0.3);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.account {
  display: flex;
  align-items: flex-end;
  list-style: none;
  margin: 0 1rem;
  margin-left: auto;
  padding: 0;
}

.account li {
  padding: 0.5rem;
  margin: 0;
  margin-right: 0.5rem;
}

.account span {
  text-decoration: none;
  color: #ffff;
  line-height: 30px;
}

.account svg,
.navToggle svg {
  color: #ffff;
  height: 30px;
  width: 30px;
}

.account svg:hover,
.navToggle svg:hover,
.close:hover {
  cursor: pointer;
}

/**********Begin Mobile Navbar************/

.navToggle {
  display: flex;
  align-items: flex-end;
  list-style: none;
  margin: 0 1rem;
  margin-left: auto;
  padding: 0;
}

.navToggle svg {
  margin-bottom: 0.5rem;
  transition: color 0.2s ease-in-out;
}

.navToggle:hover svg {
  color: lightgray;
}

.close {
  position: fixed;
  top: 15px;
  right: 15px;
  color: #ffff;
  height: 30px;
  width: 30px;
  transition: all 0.2s ease-in-out;
}

.close:hover {
  color: gray;
}

.mobileNav {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 40%;
  min-width: 320px;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(6px);
  transform: translateX(0);
  transition: transform 0.5s ease-in-out;
}

.mobileNav.open {
  visibility: visible;
  transform: translateX(0);
}

.mobileNav:not(.open) {
  transform: translateX(100%);
}

.mobileNav .navLinks {
  margin-top: 5rem;
  flex-direction: column;
  align-items: flex-start;
  background-color: transparent;
}

.mobileNav span {
  display: flex;
  color: white;
  font-size: 20px;
  padding-top: 1rem;
  padding-left: 0.5rem;
}

.mobileNav .active {
  border-radius: 5px;
}

.logout {
  margin-top: 0.5rem;
}

.logout a {
  color: red;
}


@-webkit-keyframes tracking-in-contract {
  0% {
    letter-spacing: .5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}
@keyframes tracking-in-contract {
  0% {
    letter-spacing: .5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}