.modal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
  transition: width 0.3s ease;
}

.hidden {
  display: none;
}

.modalContent {
  background-color: #212529;
  margin: 10% auto;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  border-radius: 5px;
}

.progressBar {
  width: 100%;
  height: 5px;
  background-color: #9a9da0;
  border-radius: 2.5px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.progress {
  height: 100%;
  width: 0;
  background-color: #016bb5;
  border-radius: 2.5px;
  transition: width 0.3s ease;
}

.step {
  color: #fff;
}

.step.active {
  display: block;
}

h2 {
  color: #fff;
}

p {
  margin-bottom: 25rem;
}

.btnContainer {
  display: flex;
  justify-content: center;
}

.nextBtn:disabled {
  color: #fff;
  height: 40px;
  width: 10rem;
  margin-right: 1rem;
  border-radius: 5px;
  background-color: #343a40 !important;
  border-color: rgba(1, 107, 181) !important;
  transition: all 0.25s ease-out !important;
  filter: brightness(0.7);
}

.nextBtn:disabled:hover {
  color: #fff;
  filter: brightness(0.7);
  transition: all 0.25s ease-out !important;
  cursor: not-allowed;
}

.nextBtn {
  color: #fff;
  height: 40px;
  width: 10rem;
  margin-right: 1rem;
  border-radius: 5px;
  background-color: #343a40 !important;
  border-color: rgba(1, 107, 181) !important;
  transition: all 0.25s ease-out !important;
}

.nextBtn:hover {
  color: #016bb5;
  filter: brightness(1.1);
  transition: all 0.25s ease-out !important;
}
