.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .dropdown {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .button {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    background-color: lightblue;
    border: none;
    cursor: pointer;
  }
  
  .userList {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  
  .user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid lightgray;
  }
  
  .deleteButton {
    background-color: red;
    color: white;
    border: none;
    cursor: pointer;
  }
  