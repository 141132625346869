.pageTitle {
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  align-items: top;
  border-bottom: 1px solid #a7a7aa;
  padding: 10px;
  grid-row: 1 / 2;
  grid-column: 1 / -1;
  max-height: 60px;
}

.title {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
}

.button {
  appearance: none;
  background: transparent;
  border: none;
  color: white;
  font-size: 32px;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.2s;
}

.button:hover,
.stopSpan:hover {
  color: gainsboro;
  cursor: pointer;
}

.stopSpan {
  color: red;
}
