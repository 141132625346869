/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼   Request Form Header   ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.request-form-whole-box {
  padding-top: 2rem;
  padding-bottom: 4rem;
  animation: slide-in-bottom 0.5s ease;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

@media screen and (min-width: 2001px) and (max-width: 3000px) {
  .request-form-whole-box {
    width: 70%;
    max-width: 70%;
  }
}

@media screen and (min-width: 500px) and (max-width: 2000px) {
  .request-form-whole-box {
    width: 75%;
    max-width: 75%;
  }
}

@keyframes slide-in-bottom {
  from {
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.request-title-box {
  background-color: #212529;
  min-height: 120px !important;
  padding: 0.5em;
  border: 1.5px solid rgb(154, 157, 160) !important;
  border-bottom: none !important;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  font-weight: 200 !important;
  font-size: 1.8rem !important;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.request-main-title {
  align-content: center;
  justify-content: center;
  font-size: 1.8rem;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.request-title-text {
  align-content: center;
  justify-content: center;
  font-size: 1.4rem;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼   Request Form Body/Footer   ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.request-form-row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgba(0, 0, 0, 0.1);

  border: 1.5px solid rgb(154, 157, 160) !important;
  backdrop-filter: blur(6px);
  font-size: 1.8rem !important;
}

.request-form-desc-box {
  background-color: #212529;
  padding-left: 3em;
  padding-right: 3em;
  border: 1.5px solid rgb(154, 157, 160) !important;
  border-top: none !important;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding-bottom: 2em;
}

.request-title {
  align-content: flex-start;
  padding-top: 1em;
  font-size: 2.1rem;
}

.request-info {
  text-align: left;
  padding-bottom: 1em;
  border-left: 1.5px solid rgb(154, 157, 160) !important;
  border-top: 1.5px solid rgb(154, 157, 160) !important;
  border-top-left-radius: 0.6rem;
  font-size: 1.8rem !important;
  font-weight: 200 !important;
}

.request-info p {
  font-size: 25px;
}

/*  */

.request-info ul {
  font-size: 20px;
  text-align: center;
  list-style-type: none;
  font-weight: bold;
}

.request-info ul li .subheading {
  text-decoration: none;
}

.request-info ul li p {
  font-size: 20px !important;
  font-weight: 200 !important;
  text-decoration: none !important;
}

/*  */

.request-middle-head-foot {
  font-size: 23px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: 3rem;
}

.request-middle-head-pp {
  font-size: 23px;
  padding: 0 5rem 0 1rem !important;
  margin-left: 5rem;
  border-left: 3px solid #016bb5;
  text-align: justify;
}

.request-middle-head-title {
  font-size: 23px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  display: list-item;
  list-style-type: circle;
  list-style-position: inside;
  margin-left: 1rem;
}

.request-middle-container {
  font-size: 23px;
  text-align: center;
  list-style-type: none;
  /* font-weight: bold; */
  height: 40px;
  width: 90%;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-radius: 1rem;
}

.request-middle-bold-1,
.request-middle-bold-2,
.request-middle-bold-3,
.request-middle-bold-4 {
  transition: 0.35s ease;
  display: inline;
}

/* .request-middle-bold:hover {
    color: #016bb5;
    transition: .35s ease;
  } */

.request-middle-bold-1 {
  grid-area: 1 / 1 / 2 / 2;
  border-left: 3px solid #016bb5;
}

.request-middle-bold-2 {
  grid-area: 1 / 2 / 2 / 3;
}

.request-middle-bold-3 {
  grid-area: 1 / 3 / 2 / 4;
}

.request-middle-bold-4 {
  grid-area: 1 / 4 / 2 / 5;
}
