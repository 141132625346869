.container {
  width: 100%;
}

.content {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  backdrop-filter: blur(6px);
  border: 1px solid #9a9da0;
  height: 50rem;
  overflow: scroll;
  justify-content: center;
}

@keyframes howto-menu-enter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes howto-menu-exit {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

.explorer {
  width: 100%;
  display: grid;
  justify-content: center;
}

.explorer.enter {
  animation: howto-menu-enter 500ms ease;
}
.explorer.exit {
  animation: howto-menu-exit 500ms ease;
  display: none;
}
