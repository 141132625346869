.appHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 450px;
  justify-content: center;
  align-items: center;
  border: 1px solid #9a9da0;
  border-top: none;
  backdrop-filter: blur(6px);
}

.core {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(13vw, 1fr));
  grid-gap: 16px;
  width: 100%;
  min-height: 400px;
  justify-content: center;
  align-items: center;
}

.core img {
  width: 12vw;
  max-width: 160px;
}

@media (max-width: 1100px) {
  .core {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1898px) {
  .core {
    grid-template-columns: repeat(auto-fill, minmax(246px, 1fr));
  }
}

.core > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.core > button:nth-last-child(6) {
  animation: fadeInDown 0.5s forwards ease;
  animation-delay: 0.3s;
  opacity: 0;
}

.core > button:nth-last-child(5) {
  animation: fadeInDown 0.5s forwards ease;
  animation-delay: 0.4s;
  opacity: 0;
}

.core > button:nth-last-child(4) {
  animation: fadeInDown 0.5s forwards ease;
  animation-delay: 0.5s;
  opacity: 0;
}

.core > button:nth-last-child(3) {
  animation: fadeInDown 0.5s forwards ease;
  animation-delay: 0.6s;
  opacity: 0;
}

.core > button:nth-last-child(2) {
  animation: fadeInDown 0.5s forwards ease;
  animation-delay: 0.7s;
  opacity: 0;
}

.core > button:nth-last-child(1) {
  animation: fadeInDown 0.5s forwards ease;
  animation-delay: 0.8s;
  opacity: 0;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    color: transparent;
    transform: translateY(-75px);
  }
  to {
    opacity: 1;
    color: #fff;
    transform: translateY(0px);
  }
}

.appDrawerButton {
  text-decoration: none;
  outline: none;
  box-shadow: none;
  position: relative;
  bottom: 0;
  background-color: transparent;
  max-width: 205px;
  transition: 0.3s ease-in-out;
  height: 2.5rem;
  padding: 0.5rem;
  border-radius: 0.3em 0.3em 0 0;
  font-size: 15px;
  animation: fadeIn 0.5s forwards ease;
  animation-delay: 1s;
  color: white;
  border: 1.5px solid #9a9da0;
  border-bottom: none;
  opacity: 0;
}

.appDrawerButton:hover {
  text-decoration: none;
  outline: none;
  box-shadow: none;
  border: 1.5px solid #016bb5;
  background-color: rgba(0, 0, 0, 0.15);
  transition: 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
