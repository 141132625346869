.dropdown {
  top: 60px;
  position: fixed;
  overflow: hidden;
  transform: translateX(-10px);
  height: 0px;
  animation: linkOut 0.5s 1 ease forwards;
}

.dropdown.open {
  border-bottom-left-radius: var(--border-radius);
  animation: linkIn 0.5s 1 ease forwards;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.3);
}

/*prevents animation from running on initial render*/
.dropdown.preventAnimation {
  animation-name: none;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: transparent;
}

.item {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  text-decoration: none;
  width: 293px;
  text-align: center;
  cursor: pointer;
  transition: 0.3s background-color ease-in-out;
  background-color: transparent;
  margin-top: 0;
  margin-bottom: 0;
}

.item:hover {
  background-color: #525357;
  transition: 0.3s background-color ease-in-out;
}

@keyframes linkIn {
  from {
    height: 0px;
    background-color: #212529;
    color: transparent;
  }
  to {
    height: 535px;
    background-color: #212529;
    color: #fff;
  }
}

@keyframes linkOut {
  from {
    height: 535px;
    background-color: #212529;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.3);
    color: #fff;
  }
  to {
    height: 0px;
    background-color: #212529;
    color: transparent;
  }
}
