.container {
  background-color: #212529;
  border: 1px solid #9a9da0;
  border-radius: 0.5rem 0.5rem 0 0;
  display: flex;
  flex-direction: column;

  padding: 1rem;
}

.image {
  margin-right: 1rem;
}

.subtitle {
  font-size: 20px;
  font-weight: 300;
}
