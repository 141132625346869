.closeButton {
  position: absolute;
  top: 6rem;
  right: 2.5rem;
  width: 2rem;
  height: 2rem;
  font-size: 35px;
  background-color: transparent;
  border: none;
  color: white;
  transition: all 0.2s;
  animation: fadeIn 0.5s;
  transform-origin: center center;
  display: inline-block;
}

.closeButton:before,
.closeButton:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35px;
  height: 2px;
  background-color: white;
}

.closeButton:before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.closeButton:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.closeButton:hover {
  color: #006699; /* SAIC blue */
  font-size: 40px;
  transform: scale(1.1);
}

.closeButton:hover:before,
.closeButton:hover:after {
  background-color: #006699; /* SAIC blue */
  transition: all 0.2s;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
