/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼       Login Modal Main        ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.login-text-white {
    color: white;
    display: flex;
    align-items: left;
  }
    
  .h4 {
    color: #fff !important;
  }
  
  .login-modal.show {
    background-color: transparent !important;
    background: transparent !important;
    backdrop-filter: blur(4px);
  }

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼    Login Modal Header       ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */
  
  .login-modal-logo {
    background-color: #212529;
    align-content: center !important;
    justify-content: center !important;
    align-items: center !important;
    border: 1.5px solid rgb(154, 157, 160);
    border-bottom: none !important;
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
    border-bottom: 1.5px solid rgb(154, 157, 160) !important;
  }

  .login-modal-header {
    background-color: #212529;
    align-content: center;
    justify-content: center !important;
    border: 1.5px solid rgb(154, 157, 160);
    border-bottom: none !important;
    border-top: none !important;
    border-radius: 0px !important;
    padding-top: 1.35rem !important;
  }

  /* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼     Login Modal Body       ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

  .login-modal-body {
    border: 1.5px solid rgb(154, 157, 160) !important;
    background-color: #212529;
    border-top: none !important;
    border-bottom: none !important;
  }
  
  .login-modal-ro {
    background-color: #212529;
    align-content: center;
    justify-content: center !important;
    border: 1.5px solid rgb(154, 157, 160);
    border-bottom: none !important;
    border-top: none !important;
    border-radius: 0px !important;
    padding-top: 2em !important;
  }
  
/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼     Login Modal Footer       ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

  .login-modal-footer {
    background-color: #212529;
    justify-content: center !important;
    border: 1.5px solid rgb(154, 157, 160);
    border-top: none !important;
    border-bottom: none !important;
    padding: 1em !important;
    border-radius: 0px !important;
  }

  .login-modal-footer-disc{
    background-color: #212529;
    justify-content: center !important;
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
    border: 1.5px solid rgb(154, 157, 160);
    border-top: none !important;
    padding: 1em !important;
    color: rgb(154, 157, 160);
    font-size: .55em;
  }
  
  .login-modal-button {
    width: 13em;
    background-color: #343a40 !important;
    border-color: rgba(1, 107, 181) !important;
    transition: all 0.25s ease-out !important;
  }
  
  .login-modal-button:hover {
    filter: brightness(1.2);
    transition: all 0.25s ease-out !important;
  }
  
  .login-ring-loader {
    justify-content: center;
    display: flex;
    align-items: center;
    min-height: 100vh !important;
    background-color: #343a40 !important;
  }

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼     Disclaimer/Policy       ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

  .disc-button,
  .disc-button:active{
    background-color: transparent !important;
    border: 1.5px solid rgb(154, 157, 160) !important;
    border-radius: .5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-right: .5rem;
    padding-left: .5rem;
    width: 100%;
    font-size: 1.3rem;
    font-weight: 200;
    transition: .35s ease-in-out !important;
    outline: none !important;
    box-shadow: none !important;
  }
  
  .disc-button:hover{
    border-color: rgba(1, 107, 181) !important;
  }

  .login-disc-text{
    font-size: .88rem;
    font-weight: 200;
  }

  .policy-button{
    border-top: 1.5px solid rgb(154, 157, 160);
    padding-top: .55rem;
    padding-bottom: .35rem;
    width: 100%;
    font-size: .78rem;
    font-weight: 400 !important;
    color: rgb(154, 157, 160);
    transition: all var(--speed) ease;
    margin-top: .75em;
    text-align: center;
  }

  .fail-text {
      color: red;
      font-size: 1rem;
  }

  .download-link {
      margin-top: 1rem;
  }