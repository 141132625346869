.footer {
  background-color: #212529;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 1.5px solid rgb(154, 157, 160);
  border-top: none;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  text-align: center;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.footer button {
  background-color: transparent;
  border: 1.5px solid white;
  border-radius: 2rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  cursor: default;
  display: flex;
  align-items: center;
  flex-direction: row;
  color: white;
}

.footer button:hover {
  cursor: pointer;
  border-color: gray;
  transition: border-color 200ms ease;
}

.footer span {
  max-width: 35rem;
  -webkit-transition: max-width 1s;
  transition: max-width 0.75s ease-in-out;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 10px;
  transform: translateX(2%);
  font-size: 1.1rem;
  font-weight: 200;
}

.footer img {
  width: 2rem;
}
