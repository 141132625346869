.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #212529;
  border: 1px solid #9a9da0;
  border-bottom: none;
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 1rem;
  align-items: center;
}

.button {
  border: none;
  background: transparent;
  cursor: pointer;
  max-width: 50px;
  margin-right: 1rem;
  transition: all 200ms ease;
}

.button:hover {
  cursor: pointer;
  color: lightgray;
  transform: scale(1.1);
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: right;
}

.centerTitle {
  width: 100%;
}

.filePath {
  font-size: 0.9rem;
  text-align: left;
  white-space: nowrap;
  color: gray;
  display: flex;
  flex-direction: row;
}

.filePath div:hover {
  cursor: pointer;
  color: white;
}

.search {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: right;
  padding: 0.1rem;
  width: min-content;
  margin-left: auto;
}

.search svg {
  margin-left: auto;
  margin-right: 0.5rem;
  width: 30px;
}

.search input {
  color: white;
  background-color: transparent;
  border: none;
  outline: none;
  max-width: 200px;
  max-height: 30px;
  font-size: 20px;
}
