/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼     Main Navbar       ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.h5,
h5 {
  font-size: 1.15rem;
  font-weight: 200;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.demoTab {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.nav-tabs .nav-link,
.nav-tabs .nav-link:hover {
  border: 0px;
  transition: 0.3s ease;
}

.nav-link:hover {
  box-shadow: 0px -10px 30px rgba(0, 0, 0, 0.3);
  transition: 0.3s ease;
}

.nav-tabs {
  border-bottom: none;
}

.bg-dark {
  border-bottom: 9px solid rgba(1, 107, 181);
}

.white-nav-text {
  color: #eeeeee;
  text-decoration: none;
  font-weight: 200;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.navbar-nav {
  background-color: #343a40;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.navbar-brand {
  padding-top: 0px;
  padding-bottom: 2px;
  margin-right: 1.3rem;
  margin-left: 1rem;
  font-size: 2.7rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar {
  padding-top: 1rem;
  padding-bottom: 0px;
  align-items: center;
  line-height: 0px;
}

.active {
  background-color: #016bb5;
  color: #ffff;
  box-shadow: 0px -25px 30px rgba(0, 0, 0, 0.3);
}

.ReactCollapse--collapse {
  transition: height 350ms;
}

.ReactCollapse--content {
  background-color: #6c757d;
  max-height: 30rem;
  overflow: auto;
  margin: auto;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.9);
}

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼      Mobile Navbar    ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.mobile-navbar {
  position: fixed;
  padding-top: 0px;
  justify-content: center;
  z-index: 1;
  bottom: 0;
  left: 0;
  background-color: #000000;
  width: 100%;
  visibility: hidden;
}

.mobile-navbar > * {
  visibility: visible;
  background-color: #000000;
}

.mobile-nav-link {
  background-color: #212529;
}

.nav-container {
  background-color: #212529;
  flex-direction: row;
}

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼     Footer       ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.footer {
  z-index: 1;
  background-color: #212529;
  width: 100%;
  height: 2.5rem;
  border-top: 5px solid #016bb5;
  position: fixed;
  bottom: 0;
}

.footer-bar-logo {
  vertical-align: middle;
  justify-content: end;
  margin-top: 0.13rem;
  margin-right: 0.15rem;
}

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼     Account Dropdown       ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.dropdown {
  position: absolute;
  top: 55px;
  width: 300px;
  transform: translateX(-84%);
  background-color: #212529;
  /* border-left: 1.5px solid rgb(154, 157, 160);
    border-bottom: 1.5px solid rgb(154, 157, 160); */
  border-bottom-left-radius: var(--border-radius);
  padding: 1rem;
  overflow: hidden;
  transition: all var(--speed) ease;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.3);
}

.menu {
  width: 100%;
}

.menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
  text-decoration: none;
  width: 100%;
  margin-top: 0.25em;
}

.acct-nav-item {
  padding-right: 1rem;
  padding-bottom: 0.5rem;
}

.account-hello {
  padding-right: 1rem;
}

.account-menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 0rem;
  text-decoration: none;
  width: 100%;
}

a {
  color: #fff;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.menu-item .icon-button {
  margin-right: 0.5rem;
}

.menu-item .icon-button:hover {
  filter: none;
}

.menu-item:hover {
  background-color: #525357;
}

.icon-right {
  margin-left: auto;
}

/* CSSTransition Classes  */

.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}

.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼    Quicklinks Dropdown    ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.quicklinks-tab-nav {
  background-color: #016bb5;
  color: #016bb5;
}

.quicklinks-menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
  text-decoration: none;
  width: 100%;
}

.quicklinks-dropdown {
  position: absolute;

  height: 0px;
  background-color: #212529;

  padding: 1rem;
  overflow-y: hidden;
  transition: height 500ms ease;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 990px) {
  .quicklinks-dropdown {
    width: 95%;
    bottom: 0;
    transform: translateX(-82.5%) translateY(-12.5%);
    border-radius: var(--border-radius);
  }
}

@media only screen and (min-width: 990px) {
  .quicklinks-dropdown {
    width: 325px;
    top: 55px;
    transform: translateX(-3%);
    border-top: none;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }

  .quicklinks-blue-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    overflow-x: hidden;
    background: #016bb5;
    border-top: 8px solid #016bb5;
  }
}

.quicklinks-menu {
  height: 100%;
  width: 100%;
}

.quicklinks-menu-item .icon-button:hover {
  filter: none;
}

.quicklinks-menu-item:hover {
  background-color: #525357;
}

.quicklink:hover {
  cursor: pointer;
}

/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼     Spotlight Search       ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.spotlight-search {
  border: 1.5px solid rgb(154, 157, 160);
}

.spotlight-list {
  border: 1.5px solid rgb(154, 157, 160);
}

.spotlight-modal {
  visibility: hidden;
}

.spotlight-search {
  visibility: visible;
  height: 3rem;
  width: 100%;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-align: center;
  opacity: 0.7;
}

.spotlight-search:focus {
  outline: none;
}

.spotlight-list {
  visibility: visible;
  color: white;
  width: 100%;
  height: 60vh;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  opacity: 0.85;
  background-color: #212529;
  text-align: left;
  overflow-y: auto;
}

.spotlight-result-list {
  list-style-type: none;
  padding-left: 0.5rem;
}

.spotlight-search-result {
  padding-left: 1rem;
}

.spotlight-application-result {
  width: 90%;
  text-align: center;
}
