.frame {
    background-color: var(--bs-body-color);
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 3px 3px 7px 0 rgba(0, 0, 0, 0.2),
    -4px -4px 9px 0 rgba(255, 255, 255, 0.4);
    background: rgba(33, 37, 41, 0.7);
    -webkit-animation: puff-in-center .6s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
    animation: puff-in-center .6s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
    left: 5%;
    top: 90px;
    width: 100vw;
    height: 85vh;
    margin: auto;
    /* Flexbox settings to center child elements */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 91vw;
    max-height: 1080px;
}

.reportFrame {
    border-radius: 10px;
    width: 90vw;
    height: 85vh;
    
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.title {
    backdrop-filter: blur(100px);
    border-radius: 10px;
    padding: .5rem;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);
    text-align: center; /* Keep the text centered */
    animation-delay: 5s;
    -webkit-animation: tracking-in-expand 3s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-expand 3s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.switchButton {
    background-color: #f8f9fa; /* Change to desired color */
    color: #212529; /* Change to desired color */
    border: none;
    border-radius: 10px;
    padding: .5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);
    transition: all .5s ease-in-out;
}

.switchButton:hover {
    background: transparent;
    color: white;
    box-shadow: inset 6px 6px 10px 0 rgba(0, 0, 0, 0.2),
      inset -6px -6px 10px 0 rgba(255, 255, 255, 0.5);
}

.neumorphic {
    box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.2),
      -12px -12px 24px 0 rgba(255, 255, 255, 0.5);
    font-size: 5rem;
    width: 100px;
    height: 60px;
    border-radius: 10px;
    overflow: hidden;
    padding: 1.3rem;
    display: flex;
    transition: all .5s ease-in-out;
  }

  .neumorphicPressed {
    box-shadow: inset 6px 6px 10px 0 rgba(0, 0, 0, 0.2),
      inset -6px -6px 10px 0 rgba(255, 255, 255, 0.5);
      transition: all .5s ease-in-out;
  }

  .variation2 {
    justify-content: space-around;
    align-items: center;
  }
  
  .variation2 span {
    display: block;
    position: relative;
    font-size: 1rem;
    transition: all .5s ease-in-out;
  }
  
  
  .variation2.pressed {
    color: #5ab9ea;
  }

@-webkit-keyframes puff-in-center {
    0% {
      -webkit-transform: scale(2);
              transform: scale(2);
      -webkit-filter: blur(4px);
              filter: blur(4px);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes puff-in-center {
    0% {
      -webkit-transform: scale(2);
              transform: scale(2);
      -webkit-filter: blur(4px);
              filter: blur(4px);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }

  @-webkit-keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }