.header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  background-color: transparent;
  animation: fadeInAnimation 0.5s forwards ease;
  animation-delay: 1s;
  opacity: 0;
  position: relative;
  top: 0;
  z-index: 1;
}

.title {
  flex: 1;
  font-size: 25px;
  font-weight: 600;
  color: white;
  margin-left: 15px;
}

.buttonHolder {
  display: flex;
  margin-right: 14px;
  margin-top: 8px;
}

button {
  background-color: transparent;
  border: none;
  color: #007aff;
  font-size: 17px;
  font-weight: 600;
}

.buttonHolder:hover {
  color: #016bb5;
  cursor: pointer;
  transition: all 0.2s ease;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
