.button {
  display: grid;
  align-items: center;
  justify-content: center;
}

.selector {
  display: grid;
  align-items: center;
  justify-content: center;
  position: relative;
  height: min-content;
  width: min-content;
  margin: auto;
}

.button span {
  white-space: nowrap;
  color: white;
}

.button img,
.button span {
  color: white;
  transition: all 200ms ease;
}

.button:hover img {
  transform: scale(1.1);
  cursor: pointer;
}

.blur {
  filter: blur(2.5px);
}
