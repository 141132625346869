.button {
  display: grid;
  align-items: center;
  justify-content: center;
}

.selector {
  display: grid;
  align-items: center;
  justify-content: center;
  position: relative;
  height: min-content;
  width: min-content;
  margin: auto;
}

.button img,
.button span {
  color: white;
  transition: all 200ms ease;
}

.button:hover img,
.button:hover span {
  transform: scale(1.1);
  cursor: pointer;
  color: gainsboro;
}
