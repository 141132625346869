/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼      GS Whole Box    ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */

.getting-started-whole-box {
    padding-top: 1.0em;
    background-color: transparent;
    padding-bottom: 2.5em;
    display: flex;
    flex-direction: column;
    z-index: 2;
    position: relative;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
     -khtml-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }
  
  /* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼      GS Header   ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */
  
  .getting-started-title {
    align-items: center;
    padding-top: .5rem;
    background-color: #212529;
    min-height: 10vh !important;
    top: 10px !important;
    padding: .5em;
    border: 1.5px solid rgb(154, 157, 160) !important;
    border-bottom: none !important;
    border-top-left-radius: 0.3em;
    border-top-right-radius: 0.3em;
    font-weight: 200 !important;
    display: flex;
    font-size: 1.8rem !important;
    justify-content: center;
    color: white;
  }
  
  .getting-started-title-text{
    text-align: center;
    font-size: 1.4rem;
  }
  

  
  .getting-started-row::-webkit-scrollbar {
    display: none;
  }
  
  .getting-started-title > .row {
    justify-content: center;
  }
  
  /* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼      GS Body    ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */
  
  .getting-started-dropdown-row{
    padding-left: 0px !important;
    padding-right: 0px !important;
    bottom: 0 !important;
  }

  .ptb-fancy-button {
    border: 1.5px solid #9a9da0;
    text-align: center;
    margin-top: 2rem;
    border-radius: 10px;
    padding: .25rem;
    transition: .35s ease;
    width: 75%;
    margin: auto;
    margin-top: 2rem;
  }

  .ptb-fancy-button:hover {
    border: 1.5px solid rgba(1, 107, 181);
    text-align: center;
    margin-top: 2rem;
    border-radius: 10px;
    transition: .35s ease;
    background-color: rgba(0, 0, 0, 0.25);
  }

  .ptb-download-link {
    text-decoration: none !important;
  }
  
  /* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼      GS Footer    ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */
  
  .getting-started-footer {
    align-items: center;
    background-color: #212529;
    padding: 1em;
    border: 1.5px solid rgb(154, 157, 160) !important;
    border-bottom-left-radius: 0.3em;
    border-bottom-right-radius: 0.3em;
    border-top: none !important;
    font-weight: 200 !important;
    display: flex;
    flex-direction: row;
  }
  
  .getting-started-footer-row{
  
    margin-bottom: 4rem;
    background-color: rgba(0, 0, 0, 0.25);
    --bs-gutter-x: 0rem !important;
    border: 1.5px solid rgb(154, 157, 160) !important;
    border-radius: 0.3em;
    align-items: center;
    overflow: hidden;
    justify-content: flex-end !important;
  }
  
  .video-player-col{
    --bs-gutter-x: 0rem !important;
  }
  
  .videoplayer {
    margin-left: 0 !important;
    margin-right: 0 !important;
    border-radius: .5rem;
    overflow: hidden !important;
    transition: all 350ms ease;
    
  }
  
  .video-player-title{
    border-bottom: 1.5px solid rgb(154, 157, 160) !important;
    vertical-align: text-top;
    font-size: 1.85rem;
    font-weight: 200;
    padding-bottom: .15em;
    color: white;
  }
  
  .video-player-text{
    padding-left: 1em;
    padding-right: 1em;
    font-size: 1.65rem;
    overflow: hidden;
    opacity: 1;
    transition: 350ms;
    transition-delay: 350ms;
    color: white;
  }
  
  .video-player-text-playing{
    position: absolute;
    font-size: 1.65rem;
    overflow: hidden;
    padding-left: 1em;
    max-width: 50%;
    padding-right: 1em;
    opacity: 0;
    z-index: -999;
  }
  
  .ptb-player .react-player__preview{
    background-image: url(https://software-downloads.s3-us-gov-west-1.amazonaws.com/PTB.png) !important;
    border-right: 1.5px solid rgb(154, 157, 160) !important;
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }
