.footer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  z-index: 2;
  background-color: #212529;
  width: 100%;
  border-top: 5px solid #016bb5;
  position: fixed;
  bottom: 0;
  box-shadow: 0px -20px 20px 0px rgba(0, 0, 0, 0.3);
}

.feedback {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
}

.feedback svg {
  height: 25px;
  width: 100px;
  /* margin-left: 1.75rem; */
  /* color: #9a9da0; */
  color: #fff;
  transition: 0.35s ease;
  /* background-color: #212529; */
  z-index: 3;
  height: 1.75rem;
}

.feedback svg:hover {
  color: #fff;
  transition: 0.35s ease;
  cursor: pointer;
}

.text {
  /* color: transparent; */
  color: #fff;
  cursor: default;
  font-size: 20px;
  /* transform: translateX(-30px); */
  transform: translateX(-30px);
  transition: all 0.35s ease;
  user-select: none;
}

.feedback svg:hover ~ .text {
  color: #fff;
  transition: all 0.35s ease;
  transform: translateX(-30px);
  white-space: nowrap;
}
